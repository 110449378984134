import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Row, Col, Alert } from 'react-bootstrap'
import { cap, pw } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import '../style/montserrat.scss'
import music from '../assets/music/ninda.mp3'
import logoig from '../assets/img/dinny/logoig.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar } from '../params'
import post from '../params/post'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import pathputih1 from '../assets/img/ninda/pathputih1.svg'
import pathblue1 from '../assets/img/ninda/pathblue1.svg'
import covid from '../assets/img/ninda/covid.svg'

import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';

let cmain = '#7b8961ff'
let orange = '#d2b87bff'
let black = 'rgb(38,38,38)'

let id = 'nila-arri'
let inisial_co = 'Arri'
let inisial_ce = 'Nila'
let lengkap_co = 'Herri Ferdinansyah Putra'
let lengkap_ce = 'Nila Oktavia'
let bapak_co = 'Bpk. H. Humaidi AR'
let ibu_co = 'Ibu Hj. Siti Nasroh'
let bapak_ce = "Bpk. Jauza (Alm) "
let ibu_ce = "Ibu Nuraida"
let ig_co = "arry_putrasriwijaya"
let ig_ce = "nilaoktavia_jz"

let foto_ce = "https://i.ibb.co/WHrgx9M/Individu-2.jpg"
let foto_co = "https://i.ibb.co/wRYZWc4/Individu-1.jpg"
let waktunikah = "01/17/2021"

let modal = "https://i.ibb.co/d0KHHtr/Modal-Baru-Fix.jpg"
let openlogo = pw(id, "logo.png")

let gmaps = "https://goo.gl/maps/iyND9MRgGyd1geA1A"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=NXZuNzlydGd1bmJjOTNiczkyYmZzZ2FwOTQgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'

let slide = ["	https://i.ibb.co/ZW2MT9h/DSC08575-01.jpg	",
    "	https://i.ibb.co/DzLf4XX/DSC08628-01.jpg	",
    "	https://i.ibb.co/1fxd44Z/DSC08662-01.jpg	",
    "	https://i.ibb.co/Tqkqsm7/DSC08721-01.jpg	",
    "	https://i.ibb.co/FzZJSRB/DSC08724-01.jpg	",
]
let blue = '#5e7561'

export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 2
        }
    }

    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 2000
        });
        var countDownDate = new Date(waktunikah).getTime();

        // Update the count down every 1 second
        var x = setInterval(() => {

            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            this.setState({
                days: days, hours: hours, minutes: minutes, seconds: seconds
            })


        }, 1000);

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id,"music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${this.nama.current.value}", hadir: "", jumlahhadir: "", kepada: "${id}", pesan:"${this.pesan.current.value}",alamat: ""`
                              )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            }else{
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }


    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, sesi } = this.state
        let slider = []
        slide.map(v => {
            slider.push(gambar(v, 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=800&h=520'))
        })
        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slide[0]}
                    url={`https://undanganku.me/${id}`}
                />

                <div id='gold5'>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='position-relative' style={{
                            backgroundImage: 'none',
                            backgroundColor: cmain
                        }}>
                            <div className="position-absolute" style={{ left: 0, bottom: '10%', width: '100vw' }}>
                                <Item>
                                    <Col xs={12} md={4} className='m-2 m-md-0 ' style={{marginBottom:'-50px'}}>
                                        <img className='img-fluid w-100 p-2'
                                            src={openlogo} data-aos="fade-left" data-aos="fade-left" />
                                    </Col>
                                </Item>
                                <Item>
                                    <p style={{fontFamily:'Tinos,serif',fontSize:'18px'}} className="text-center">
                                        KEPADA YTH.<br/>
                                        <br/>
                                        <div style={{transform:'scale(1.4)'}}>
                                        {query ? query : ''}
                                        </div>
                                        
                                    </p>
                                </Item>
                                
                                <Row className='justify-content-center'>
                                    <div onClick={() => { this.play() }}
                                        // data-aos="fade-left"
                                        className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                        style={{ marginTop: 0, color: 'white', borderColor: orange,fontFamily:'Tinos,serif',borderColor:'white' }}>
                                        Open Invitation
                                    </div>
                                </Row>

                            </div>
                            {/* <img className="w-100 img-fluid position-absolute" style={{ left: 0, bottom: 0 }}
                                src={pathblue1}
                            /> */}

                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'white',backgroundImage:`url('https://vectorforfree.com/wp-content/uploads/2019/04/Leaf_Pattern_VectorForFree_02.jpg')`,
                            backgroundSize:'25%' }}>
                                <Container fluid style={{
                                    backgroundColor: cmain
                                }}>
                                    <Container className="dinny px-3 py-5 " >
                                        <Item>

                                            <img src={'https://i.ibb.co/9gFs70g/Bird.png'} style={{ width: '50px', height: '50px' }} />

                                            <p className="text-center  px-3 py-3 w-100 text-center"
                                                style={{
                                                    color: 'white',
                                                    fontFamily: 'Montserrat,Helvetica,Arial,Lucida,sans-serif',
                                                    fontSize: '14px'

                                                }} data-aos="fade-left">
                                                “And of His signs is that He created for you from your selves mates that you may find tranquility in them; and He placed between you affection and mercy. Indeed in that are signs for a people who give thought.”
                                            <br />
                                                <br />
                                            ( QS. Ar- Rum 21 )
                                        </p>
                                        </Item>

                                    </Container>
                                </Container>
                                <Container fluid style={{ backgroundColor: cmain, padding: 0, position: 'relative' }}>
                                    <img src={pathputih1} className="position-absolute"
                                        style={{ bottom: 0, left: 0, width: '110vw' }} />
                                </Container>
                                <Container className="py-3" >
                                    <Item>
                                        <Col xs={10} md={4}>
                                            <img src={pw(id, "bismillah-black.svg")} className="img-fluid w-100" />
                                        </Col>
                                        <p className="fs16 text-center  px-3 py-3 w-100 text-center"
                                            style={{ color: '#171717', fontSize: '16px' }} data-aos="fade-left">
                                            Assalaamu'alaikum Warahmatullahi  Wabarakatuh<br /><br />
                                            Dengan memohon rahmat dan ridha Allah SWT,<br />
                                            kami bermaksud menyelenggarakan pernikahan kami
                                        </p>
                                    </Item>
                                </Container>
                                <Container style={{ color: 'black' }} className="py-5">
                                    <Item>
                                        <Col md={5}>
                                            <h1 className="w-100 text-left"
                                                style={{
                                                    fontFamily: 'Parisienne, cursive', color: cmain,
                                                    fontSize: '36px',
                                                    fontWeight:'700'
                                                }} data-aos="fade-left">
                                                {lengkap_ce}
                                            </h1>
                                            <img src={logoig} className='btn p-0 my-3'
                                                data-aos="fade-right"
                                                onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                            <p className="w-100 text-left" style={{ fontSize: '16px' }}>
                                                <b>Putri kelima dari: </b><br />
                                                {bapak_ce}<br />
                                                & {ibu_ce}
                                            </p>
                                        </Col>
                                        <Col md={2} >
                                            <Row className="h-100">
                                                <h1 className="w-100 text-center my-auto"
                                                    style={{
                                                        fontFamily: 'Parisienne, cursive',
                                                        color: cmain, fontSize: '72px'
                                                    }} data-aos="fade-left">
                                                    &
                                            </h1>
                                            </Row>
                                        </Col>
                                        <Col md={5}>
                                            <h1 className="w-100 text-right"
                                                data-aos="fade-right"
                                                style={{
                                                    fontFamily: 'Parisienne, cursive', color: cmain,
                                                    fontSize: '36px',fontWeight:'700'
                                                }}>
                                                {lengkap_co}
                                            </h1>

                                            <div className="text-right">


                                                <img src={logoig} className='btn p-0 my-3'
                                                    onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />
                                            </div>
                                            <p className="w-100 text-right" data-aos="fade-left"
                                                style={{ fontSize: '16px' }}>
                                                <b>Putra ketiga dari: </b><br />{bapak_co}<br />
                                                & {ibu_co}
                                            </p>
                                        </Col>
                                    </Item>
                                </Container>

                                <Container fluid className="text-center px-4 dinny py-3"
                                    style={{ color: 'black' }} >
                                    <Item>
                                        <p style={{ fontSize: '16px' }} data-aos="zoom-in">
                                            Yang Insyaa Allah akan dilaksanakan pada:
                                        </p>
                                    </Item>

                                    <Item>
                                        <Col md={5}>
                                            <p className="w-100 text-center" style={{ color: 'black', fontSize: '18px' }} data-aos="fade-right">
                                                <b style={{ color: orange, fontSize: '36px', fontFamily: 'Sacramento, cursive' }}>
                                                    <img src={'https://www.flaticon.com/svg/static/icons/svg/2905/2905065.svg'}
                                                        width="50px" height="50px" /><br />Akad Nikah </b><br />
                                                <span style={{ fontSize: '16px' }}>

                                                    Ahad, 17 Januari 2021<br />
                                                    10.00 WIB - Selesai
                                             </span>

                                            </p>
                                            
                                        </Col>
                                        <Col md={5} className="pt-3 pt-md-0">
                                            <p className=" w-100 text-center" style={{ color: 'black', fontSize: '18px' }} data-aos="fade-right">
                                                <b style={{ color: orange, fontSize: '36px', fontFamily: 'Sacramento, cursive' }}
                                                >
                                                    <img src={'https://www.flaticon.com/svg/static/icons/svg/703/703213.svg'}
                                                        width="50px" height="50px" /><br />
                                                    Resepsi</b><br />
                                                <span style={{ fontSize: '16px' }}>

                                                    Ahad, 17 Januari 2021<br />
                                                    11.00 WIB WIB - Selesai
                                             </span>
                                            </p>
                                           
                                        </Col>
                                    </Item>
                                    <Item>
                                        <p style={{ fontSize: '16px' }} data-aos="fade--left">
                                            <b>Jln. Bungaran IV Lrg. Swadaya I RT. 14 RW. 03 No. 688, 8 Ulu Palembang 30252

                                            </b>
                                        </p>
                                    </Item>
                                    
                                    <Item>
                                        <Col xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open(gmaps)
                                            }}
                                            data-aos="fade-right"
                                            className="p-2 mx-sm-2 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            data-aos="fade-left"

                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                </Container>

                                <Container className="py-3">
                                    <Item>
                                        <Col xs={12} md={6}>
                                            <img src={covid} className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                </Container>

                                <Container id='sectiongold57'>
                                    <h1 className="w-100 text-center" style={{
                                        fontFamily: '"Marck Script", cursive',
                                        color: cmain,
                                        fontSize: '36px'
                                    }}>
                                        Save The Date
                                    </h1>
                                    <div data-aos="fade-right">

                                        <Item>
                                            <div data-aos="fade-left" data-aos-duration="1000"
                                                className='col-10 col-lg-8 kotak'
                                                style={{ backgroundColor: cmain }}>
                                                <Item>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {days}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Days
                                                        </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot'>:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {hours}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Hours
                                                    </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot'>:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div >
                                                                {minutes}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Mins
                                                    </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot' >:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {seconds}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Secs
                      </span>
                                                        </Item>
                                                    </div>
                                                </Item>

                                            </div>
                                        </Item>
                                    </div></Container>

                                <Container id='sectiongold56'>
                                    <div className='pt-3' data-aos="fade-left">

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4 ' style={{ fontSize: '16px' }}>
                                                            Jika Allah berkehendak menyatukan dua hati, maka keduanya pasti akan bersatu walaupun diantara keduanya terbentang langit dan bumi.
</p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>

                                <Container fluid style={{ backgroundColor: cmain }} className="pb-3">
                                    <Container id='sectiongold58' >

                                        <div className='pt-3 mt-4 mt-lg-5 mb-lg-3' data-aos="fade-right">
                                            <Item>
                                                <Col xs={4} lg={2}>
                                                    <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                                </Col>
                                            </Item>
                                            <Item>
                                                <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                    <Item>
                                                        <h1 className="w-100 text-center" style={{
                                                            fontFamily: '"Marck Script", cursive',
                                                            color: cmain
                                                        }}>
                                                            Send Your Wishes
</h1>
                                                    </Item>
                                                    <Item>
                                                        <form className="col-12 w-100">
                                                            <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                            <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                            <Item>
                                                                <Col xs={12} className=''>
                                                                    {
                                                                        submitted == true ? (
                                                                            <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                                Pesan anda sudah disampaikan
                                                                            </Alert>) : (submitted === false ? (
                                                                                <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                    {
                                                                                        err.map(val => {
                                                                                            return (
                                                                                                <li>{val}</li>
                                                                                            )
                                                                                        })
                                                                                    }

                                                                                </Alert>
                                                                            ) : false)
                                                                    }

                                                                </Col>
                                                            </Item>
                                                            <Item>
                                                                <div className='col-6 button rounded btn'
                                                                    onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                            </Item>
                                                        </form>
                                                    </Item>
                                                </div>
                                            </Item>
                                        </div>
                                    </Container>
                                </Container>
                                <Foot ig={logoig} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

